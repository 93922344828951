//=include ../../../bower_components/swiper/dist/js/swiper.min.js

;(function($){
  $(function(){
    
    $(".swiper-container .banner_wrapper").addClass("swiper-slide");
    var mySwiper = new Swiper('.swiper-container', {
        pagination: '.swiper-pagination',
        speed: 400,
        spaceBetween: 100,
        paginationType:'bullets',
        autoplay:3000
    });
  });
}(jQuery));
